<div class="page">
  <div class="container pb-0" *ngIf="shouldShowStockDistribution && !isOrderNow">
    <div class="tab-container">
      <div
        class="tab-option"
        *ngFor="let tab of tabOptions"
        [ngClass]="{ active: tab.isActive }"
        (click)="toggleActive(tab)"
      >
        {{ trans(tab.title) }}
        <span
          *ngIf="tab.title !== 'CATALOG_PAGE.CATALOG_TAB_TITLE'"
          class="new-status-tag caption2--medium"
        >
          {{ trans('CATALOG_PAGE.NEW_TAB') }}
        </span>
      </div>
    </div>
  </div>
  <div class="container" *ngIf="!isOrderNow && !loading">
    <div class="row mt-0">
      <div class="title-container col-12">
        <p *ngIf="closingMessage" class="market-unavailability-message">
          {{ closingMessage }}
        </p>
        <p class="title heading2--extra-bold">
          <ng-container *ngIf="selectedTab === 'catalog'; else lockedProductTitle">
            {{ trans('CATALOG_PAGE.CATALOG_TITLE') }}
          </ng-container>
          <ng-template #lockedProductTitle>
            {{ trans('CATALOG_PAGE.LOCKED_TITLE') }}
          </ng-template>
          <span class="heading2--bold"
            >({{ products.length }} {{ trans('CATALOG_PAGE.CATALOG_ITEMS') }})</span
          >
        </p>
      </div>
      <app-selected-country-tooltip class="col-12"></app-selected-country-tooltip>
    </div>
  </div>

  <div class="loader" *ngIf="loading">
    <loader size="md" *ngIf="loading" [loading]="loading"></loader>
  </div>
  <div class="container p-0">
    <div
      class="second-product-discount"
      *ngIf="shouldShowDiscountAsSecondProduct && showDiscountAsSecondProductMessage"
    >
      <img
        class="second-product-discount__img"
        src="assets/iconfont-input/discount-lightning.svg"
      />
      <p class="second-product-discount__text body1--bold">
        {{ trans('CATALOG_PAGE.SECOND_IN_PLACEMENT_CATALOG_MESSAGE') }}
      </p>
    </div>
    <app-quantity-discount-banner
      *ngIf="shouldShowQuantityDiscountBanner && bannerEnabled && selectedTab === 'catalog'"
      [shouldShowDetailLine]="true"
      (updateBannerVisibility)="bannerDisplayCheck()"
    ></app-quantity-discount-banner>
  </div>
  <div *ngIf="selectedTab !== 'catalog' && shouldEnableProductLockRequest">
    <app-product-lock-request-status></app-product-lock-request-status>
  </div>
  <div
    class="container mt-0 mt-md-2 mb-md-5 catalog-table"
    [ngClass]="{ 'container-padding-override': selectedTab !== 'catalog' }"
    *ngIf="!isOrderNow && !loading"
  >
    <div class="row mt-1" *ngIf="selectedTab === 'catalog'">
      <div class="col-12 d-flex flex-wrap">
        <div class="d-flex">
          <div class="select-all-container d-flex align-items-center">
            <mat-checkbox [(ngModel)]="selectAll" (change)="onToggleSelectAll()"></mat-checkbox>
            <p class="select-all-text body1--bold">{{ trans('CATALOG_PAGE.SELECT_ALL') }}</p>
          </div>
          <div class="d-flex align-items-center">
            <button
              appHoverStyle
              class="remove-selected-button body1--bold"
              (click)="onDeleteSelectedProducts()"
            >
              {{ trans('CATALOG_PAGE.DELETE_SELECTED') }}
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <table
          class="table d-none d-md-table"
          [ngClass]="{ 'border-spacing-override': selectedTab !== 'catalog' }"
        >
          <thead>
            <tr>
              <th scope="col"></th>
              <th class="caption1--bold" scope="col">
                {{ trans('CATALOG_PAGE.CATALOG_TABLE.PRODUCT_TITLE') }}
              </th>
              <th class="caption1--bold" scope="col">
                {{ trans('CATALOG_PAGE.CATALOG_TABLE.PRICE_TITLE') }}
              </th>
              <th class="caption1--bold" scope="col">
                {{ trans('CATALOG_PAGE.CATALOG_TABLE.PROFIT_TITLE') }}
              </th>
              <th
                class="caption1--bold"
                *ngIf="shouldShowVAT && selectedTab === 'catalog'"
                scope="col"
              >
                {{ trans('CATALOG_PAGE.CATALOG_TABLE.TAX_TITLE') }}
              </th>
              <th
                class="caption1--bold"
                *ngIf="shouldShowVAT && selectedTab === 'catalog'"
                scope="col"
              >
                {{ trans('CATALOG_PAGE.CATALOG_TABLE.NET_PROFIT_TITLE') }}
              </th>
              <th class="caption1--bold" scope="col">
                {{ trans('CATALOG_PAGE.CATALOG_TABLE.PRODUCT_AVAILABILITY_TITLE') }}
              </th>
              <th scope="col"></th>
            </tr>
          </thead>
          <tbody class="catalog-table" *ngFor="let product of products; index as idx">
            <tr>
              <td>
                <div
                  *ngIf="selectedTab === 'catalog'"
                  class="product-actions d-flex flex-column justify-content-around align-items-center"
                >
                  <mat-checkbox
                    [(ngModel)]="product.productIsSelected"
                    (change)="onToggleProductSelection()"
                  >
                  </mat-checkbox>
                  <button
                    appHoverStyle
                    class="delete-button body1--bold"
                    (click)="onDeleteProduct(product)"
                  >
                    X
                  </button>
                </div>
              </td>
              <td class="information-cell">
                <div class="d-flex align-items-center">
                  <a
                    *ngIf="product.isAvailableToSeller"
                    [routerLink]="['/product-details', product._id]"
                  >
                    <div
                      class="lock-product-icon"
                      *ngIf="shouldShowStockDistribution && product.availabilityInfo?.stockInfo"
                    >
                      <img src="assets/img/locked-products-lock.svg" />
                    </div>

                    <img
                      loading="lazy"
                      appHoverStyle
                      class="product-image"
                      [src]="product.productPicture"
                      [alt]="product.productName"
                    />
                  </a>
                  <img
                    loading="lazy"
                    *ngIf="!product.isAvailableToSeller"
                    class="product-image ml-2"
                    [src]="product.productPicture"
                    [alt]="product.productName"
                  />
                  <div class="name-and-category">
                    <a
                      *ngIf="product.isAvailableToSeller"
                      [routerLink]="['/product-details', product._id]"
                    >
                      <p appHoverStyle class="product-name link body1--bold">
                        {{ product.productName }}
                      </p>
                    </a>
                    <p *ngIf="!product.isAvailableToSeller" class="product-name body1--bold">
                      {{ product.productName }}
                    </p>
                    <p class="product-original-price caption1--bold">
                      {{ trans('CATALOG_PAGE.CATALOG_TABLE.ORIGINAL_PRICE') }}:
                      <ng-container *ngIf="!product.sale?.productPrice; else productOnSale">
                        <span class="caption1--bold"
                          >{{ product.productPrice }}
                          {{ currency | currencyTranslate | translate }}</span
                        >
                      </ng-container>
                      <ng-template #productOnSale>
                        <span
                          ><s class="caption1--bold">{{ product.productPrice }}</s></span
                        >
                        <span class="caption1--bold">
                          <b class="caption1--bold"> {{ product.sale.productPrice }} </b>
                          {{ currency }}</span
                        >
                      </ng-template>
                    </p>
                    <div
                      class="product-lock-section"
                      *ngIf="
                        shouldShowStockDistribution &&
                        product.availabilityInfo?.stockInfo &&
                        !product.stockIsExpired
                      "
                    >
                      <div
                        class="locked-period"
                        *ngIf="
                          product.availabilityInfo?.stockInfo.expiresAt &&
                          (product.remainingHours || product.remainingDays)
                        "
                      >
                        <p class="caption1--regular">
                          {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.TO_BE_UNLOCKED') }}:
                        </p>
                        <div
                          class="remaining-period-status caption1--regular"
                          [ngClass]="
                            product.remainingDays > 7 && !product.remainingHours
                              ? 'status-success'
                              : 'status-error'
                          "
                        >
                          <img src="assets/img/timer.svg" />
                          <span
                            >{{
                              !product.remainingHours
                                ? product.remainingDays
                                : product.remainingHours
                            }}
                            <span *ngIf="!product.remainingHours">{{
                              trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.LOCKED_DAYS')
                            }}</span>
                            <span *ngIf="product.remainingHours">{{
                              trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.LOCKED_HOURS')
                            }}</span>
                          </span>
                          <span *ngIf="product.remainingDays < 3 || product.remainingHours">
                            {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.DONT_MISS_OUT') }}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="expiry-product-container" *ngIf="product.stockIsExpired">
                      <img src="{{ assetsPath + 'white-box.svg' }}" />
                      <span
                        class="caption1--regular"
                        *ngIf="product.stockExpiryType === 'quantity'"
                        >{{
                          trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.QUANTITY_EXPIRED')
                        }}</span
                      >
                      <span class="caption1--regular" *ngIf="product.stockExpiryType === 'time'">{{
                        trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.TIME_EXPIRED')
                      }}</span>
                      <span class="caption1--regular mis-0">
                        {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.EXPIRY_LABEL') }}
                      </span>
                    </div>
                    <div class="product-variants">
                      <span *ngIf="product.productColor" class="color-variant">
                        <span class="caption1--regular">اللون:&nbsp;</span>
                        <span
                          [ngStyle]="{
                            backgroundColor: product.productColorHex
                          }"
                          class="color-ball"
                        ></span>
                        <span class="caption1--regular">&nbsp;{{ product.productColor }}</span>
                      </span>
                      <span *ngIf="product.productSize" class="size-variant caption1--regular"
                        >المقاس: {{ product.productSize }}</span
                      >
                    </div>
                  </div>
                </div>
              </td>
              <td [ngClass]="{ 'price-cell': selectedTab === 'catalog' }">
                <ng-container *ngIf="selectedTab !== 'catalog'; else showPriceEdit">
                  <div class="d-flex justify-content-center">
                    <p class="color-main body1--bold">
                      {{ product.sale ? product.sale?.productPrice : product.productPrice }}
                    </p>
                  </div>
                </ng-container>
                <ng-template #showPriceEdit>
                  <div *ngIf="!product.priceEditable" class="d-flex justify-content-center">
                    <p class="text-center product-price body1--bold">
                      {{ product.merchantSelectedPrice }}
                    </p>
                    <button
                      appHoverStyle
                      class="btn d-flex align-items-center primary-button edit-price-button"
                      (click)="onEditPrice(product)"
                    >
                      <i class="icon icon-edit" aria-hidden="true"></i>
                    </button>
                  </div>

                  <div *ngIf="product.priceEditable" class="d-flex align-price-box">
                    <input
                      type="number"
                      class="product-price product-price-input text-center body1--bold"
                      [(ngModel)]="product.merchantSelectedPrice"
                    />
                    <button
                      appHoverStyle
                      class="btn primary-button edit-action"
                      (click)="onConfirmEdit(product)"
                    >
                      <i class="icon icon-tick"></i>
                    </button>
                    <button
                      appHoverStyle
                      class="btn secondary-button edit-action"
                      (click)="onCancelEdit(product)"
                    >
                      <i class="icon icon-close"></i>
                    </button>
                  </div>
                  <div
                    class="price-range-container"
                    *ngIf="
                      isPriceRangeEnabled && product.pricingRange?.min && product.pricingRange?.max
                    "
                  >
                    <p class="caption2--bold price-range-container__title">
                      {{ trans('CATALOG_PAGE.RECOMMENDED_SELLING_PRICE') }}:
                    </p>
                    <p class="range">
                      <span class="range__title">{{ trans('CATALOG_PAGE.MINIMUM') }}:</span>
                      <span class="range__amount">{{ product.pricingRange?.min }} </span>
                      <span class="range__to">{{ trans('CATALOG_PAGE.TILL') }}</span>
                      <span class="range__title">{{ trans('CATALOG_PAGE.MAXIMUM') }}:</span>
                      <span class="range__amount">{{ product.pricingRange?.max }} </span>
                    </p>
                  </div>
                </ng-template>
              </td>
              <td>
                <div class="d-flex justify-content-center">
                  <p
                    class="text-center"
                    [ngClass]="shouldShowVAT ? 'profit-vat-price' : 'product-net-profit'"
                  >
                    <span
                      class="body1--bold"
                      [ngClass]="{ 'color-yellow': selectedTab !== 'catalog' }"
                      *ngIf="!product.sale?.productProfit; else profitOnSale"
                    >
                      {{ product.productProfit }}
                    </span>
                    <ng-template #profitOnSale>
                      <span
                        ><s class="body1--medium">{{
                          product.productOriginalProfit -
                            product.productSaleOrOrignalPrice +
                            product.customPrice
                        }}</s></span
                      >
                      <p class="sale-product-profit body1--bold">
                        {{
                          product.sale?.productProfit -
                            product.productSaleOrOrignalPrice +
                            product.customPrice
                        }}
                      </p>
                    </ng-template>
                  </p>
                </div>
              </td>
              <td *ngIf="shouldShowVAT && selectedTab === 'catalog'">
                <ng-container *ngIf="product.$vat | async as vat">
                  <p class="text-center profit-vat-price body1--bold">
                    {{ vat }}{{ vat > 0 ? '-' : '' }}
                  </p>
                </ng-container>
              </td>
              <td *ngIf="shouldShowVAT && selectedTab === 'catalog'">
                <p class="text-center product-net-profit body1--bold">
                  {{ product.$netProfit | async }}
                </p>
              </td>
              <td>
                <p
                  *ngIf="
                    !userHasStockAvailability ||
                    !product.isAvailableToSeller ||
                    product.productAvailability === productAvailabilityEnum.available_for_preorder
                  "
                  class="text-center product-availability caption1--bold"
                  [ngStyle]="{
                    color:
                      product.isAvailableToSeller || productIsAvailableToSellerAndPreOrderable
                        ? product.productAvailabilityColor
                        : '#ff4966'
                  }"
                >
                  {{
                    product.isAvailableToSeller || productIsAvailableToSellerAndPreOrderable
                      ? (product.productAvailabilityText | productAvailability | translate)
                      : trans('PRODUCTS_PAGE.PRODUCT_CARD.PRODUCT_AVAILIBILITY_NOT_AVAILABLE')
                  }}
                </p>
                <ng-container
                  *ngIf="
                    shouldShowStockDistribution &&
                      product.availabilityInfo?.stockInfo?.totalLockedStock &&
                      !product.stockIsExpired;
                    else noStockInfo
                  "
                >
                  <p class="caption1--regular text-center">
                    {{ trans('CART.REMAINING') }} :
                    <span class="caption1--bold">
                      {{ product.availabilityInfo?.stockInfo?.remainingStock }}
                    </span>
                  </p>
                  <div class="progress-bar locked-quantity-container">
                    <mat-progress-bar
                      mode="determinate"
                      [value]="
                        ((product.availabilityInfo?.stockInfo?.totalLockedStock -
                          product.availabilityInfo?.stockInfo?.remainingStock) *
                          100) /
                        product.availabilityInfo?.stockInfo?.totalLockedStock
                      "
                    ></mat-progress-bar>
                  </div>
                </ng-container>
                <ng-template #noStockInfo>
                  <p
                    *ngIf="
                      userHasStockAvailability &&
                      product.isAvailableToSeller &&
                      product.productAvailability !== productAvailabilityEnum.available_for_preorder
                    "
                    class="product-availability caption1--bold"
                    [ngClass]="{ 'text-center': selectedTab !== 'catalog' }"
                  >
                    {{ trans('PRODUCTS_PAGE.PRODUCT_CARD.STOCK_AVAILIBILITY_LABEL') }}
                  </p>
                </ng-template>
              </td>
              <td>
                <div
                  class="d-flex flex-column align-items-center"
                  *ngIf="!isDuplicateAccount; else isDuplicate"
                >
                  <button
                    appHoverStyle
                    [disabled]="
                      !product.isAvailableToSeller ||
                      product.price !== product.productSaleOrOrignalPrice
                    "
                    [ngClass]="{
                      'disabled-button':
                        !product.isAvailableToSeller ||
                        product.price !== product.productSaleOrOrignalPrice
                    }"
                    class="action-button add-to-cart-button primary-button"
                    (click)="onAddToCart(product)"
                  >
                    <img loading="lazy" src="../../../assets/img/cart.svg" />
                    <span class="body1--bold">{{ trans('PRODUCTS_PAGE.ADD_TO_CART') }}</span>
                  </button>
                  <button
                    *ngIf="selectedTab === 'catalog'"
                    appHoverStyle
                    [disabled]="
                      !product.isAvailableToSeller ||
                      product.price !== product.productSaleOrOrignalPrice ||
                      isMarketClosed
                    "
                    [ngClass]="{
                      'disabled-button':
                        !product.isAvailableToSeller ||
                        product.price !== product.productSaleOrOrignalPrice ||
                        isMarketClosed
                    }"
                    class="action-button order-now-button secondary-button body1--bold"
                    (click)="onOrderNow(product)"
                  >
                    {{ trans('CATALOG_PAGE.CATALOG_TABLE.ORDER_NOW') }}
                  </button>
                  <div
                    *ngIf="isStoresEnabled && userHasStore"
                    class="add-to-store"
                    [ngClass]="product.addedToStore ? 'add-to-store__added' : ''"
                  >
                    <button
                      [disabled]="
                        !product.isAvailableToSeller ||
                        product.price !== product.productSaleOrOrignalPrice
                      "
                      [ngClass]="{
                        'disabled-button':
                          !product.isAvailableToSeller ||
                          product.price !== product.productSaleOrOrignalPrice
                      }"
                      class="add-to-store__btn"
                      (click)="onAddToStore(product)"
                    >
                      <img
                        class="add-to-store__btn__icon"
                        src="{{
                          product.addedToStore
                            ? assetsPath + 'added-store.svg '
                            : assetsPath + 'header-icons/shop-basket.svg'
                        }}"
                      />
                      <p *ngIf="!product.addedToStore" class="body1--bold">
                        {{ trans('STORES.ADD_TO_STORE') }}
                      </p>
                      <p *ngIf="product.addedToStore" class="body2--bold">
                        {{ trans('STORES.ADDED_TO_STORE') }}
                      </p>
                    </button>
                    <span class="store-tooltip">
                      <img src="{{ assetsPath + storeProvider + '.svg' }}" />
                      <p class="caption2--bold">{{ trans('STORES.ADD_TO_STORE') }}</p>
                    </span>
                  </div>
                  <button
                    appHoverStyle
                    *ngIf="false"
                    class="action-button outline-primary-button body1--bold"
                  >
                    {{ trans('CATALOG_PAGE.NOTIFY_ME_WHEN_PRODUCT_IS_AVAILABLE') }}
                  </button>
                </div>
                <ng-template #isDuplicate>
                  <div class="duplicate-account">
                    <img src="assets/img/product-details-icons/lock.svg" alt="" />
                    {{ trans('wallet.duplicateAccountError') }}
                  </div>
                </ng-template>
              </td>
            </tr>
            <tr *ngIf="product.stockIsExpired && selectedTab !== 'catalog'">
              <td class="td__empty-cell"></td>
              <td colspan="4" class="td__price-change">
                <div class="contact-manager-section">
                  <p class="caption1--bold">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.EXPIRY_NOTICE_HEAD') }}
                  </p>
                  <p class="caption2--regular">
                    {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_BASIC_INFO.EXPIRY_NOTICE_TEXT') }}.
                  </p>
                </div>
              </td>
            </tr>
            <tr *ngIf="product.price !== product.productSaleOrOrignalPrice">
              <td class="td__empty-cell"></td>
              <td colspan="4" class="td__price-change">
                <p class="price-change-text caption1--regular">
                  <i class="icon icon-info"></i>
                  {{ trans('CATALOG_PAGE.CATALOG_TABLE.PRODUCT_HAS_BEEN_CHANGED_FROM') }}
                  <span class="price-text caption1--regular">{{ product.price }}</span>
                  {{ trans('CATALOG_PAGE.CATALOG_TABLE.TO') }}
                  <span class="prod-price-text caption1--regular">{{
                    product.productSaleOrOrignalPrice
                  }}</span>
                  {{ currency | currencyTranslate | translate }}
                  {{ trans('CATALOG_PAGE.CATALOG_TABLE.PLEASE_UPDATE_PRICE_TO') }}
                </p>
              </td>
            </tr>
            <tr
              *ngIf="
                product.discountedAsSecondInPlacement?.enabled && shouldShowDiscountAsSecondProduct
              "
            >
              <td class="td__empty-cell"></td>
              <td colspan="2">
                <div class="second-discount-container">
                  <div class="second-product-discount mt-0 mb-2">
                    <img src="assets/iconfont-input/discount-lightning.svg" />
                    <p class="caption1--bold second-product-discount__label-text">
                      {{ trans('PRODUCTS_PAGE.CAROUSEL_NAMES.SECOND_IN_PLACEMENT_CAROUSEL_NAME') }}
                    </p>
                  </div>
                  <div class="discounted-price">
                    <p class="caption1--regular">
                      {{ trans('CATALOG_PAGE.CATALOG_TABLE.SELLING_PRICE_UPTO') }} <br />
                      <span class="caption1--regular">
                        {{ product.discountedAsSecondInPlacement?.discountedPrice }}
                        {{ currency | currencyTranslate | translate }}
                      </span>
                    </p>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="row no-gutters mobile-product-card d-md-none"
          *ngFor="let product of products; index as idx"
        >
          <div
            class="col-3 d-flex justify-content-center align-items-center product-image-container"
          >
            <a [routerLink]="['/product-details', product._id]">
              <img
                loading="lazy"
                class="product-image"
                [src]="product.productPicture"
                [alt]="product.productName"
              />
            </a>
          </div>
          <div class="col-9">
            <div class="float-left d-flex flex-column">
              <mat-checkbox
                [(ngModel)]="product.productIsSelected"
                (change)="onToggleProductSelection()"
              >
              </mat-checkbox>
              <button
                appHoverStyle
                class="delete-button body1--bold"
                (click)="onDeleteProduct(product)"
              >
                X
              </button>
            </div>
            <div class="name-and-category">
              <a [routerLink]="['/product-details', product._id]">
                <p class="product-name body1--bold">{{ product.productName }}</p>
              </a>
            </div>
            <p
              *ngIf="
                !userHasStockAvailability ||
                !product.isAvailableToSeller ||
                product.productAvailability === productAvailabilityEnum.available_for_preorder
              "
              class="product-availability caption1--bold"
              [ngStyle]="{ color: product.productAvailabilityColor }"
            >
              {{ product.productAvailabilityText }}
            </p>
            <p
              *ngIf="
                userHasStockAvailability &&
                product.isAvailableToSeller &&
                product.productAvailability !== productAvailabilityEnum.available_for_preorder
              "
              class="product-availability caption1--bold"
            >
              اعرف توافر المنتج في التفاصيل
            </p>
            <p class="product-original-price caption1--bold">
              السعر الاصلي:
              <ng-container *ngIf="!product.sale?.productPrice; else productOnSale">
                <span class="caption1--bold">{{ product.productPrice }}</span>
              </ng-container>
              <ng-template #productOnSale>
                <span
                  ><s class="caption1--bold">{{ product.productPrice }}</s></span
                >
                <span>
                  <b class="caption1--bold"> {{ product.sale.productPrice }} </b>
                </span>
              </ng-template>
            </p>
            <div class="d-flex m-0">
              <div class="d-flex">
                <p class="product-price-text caption1--bold">السعر:</p>
                <div *ngIf="!product.priceEditable" class="d-flex justify-content-center mr-1">
                  <p class="text-center product-price body1--bold">
                    {{ product.merchantSelectedPrice }}
                  </p>
                  <button
                    appHoverStyle
                    class="btn d-flex align-items-center primary-button edit-price-button"
                    (click)="onEditPrice(product)"
                  >
                    <i class="icon icon-edit" aria-hidden="true"></i>
                  </button>
                </div>

                <div *ngIf="product.priceEditable" class="d-flex justify-content-center mr-1">
                  <input
                    type="number"
                    class="product-price product-price-input text-center body1--bold"
                    [(ngModel)]="product.merchantSelectedPrice"
                  />
                  <button
                    appHoverStyle
                    class="btn primary-button edit-action"
                    style="border-radius: 0"
                    (click)="onConfirmEdit(product)"
                  >
                    <i class="icon icon-tick"></i>
                  </button>
                  <button
                    appHoverStyle
                    class="btn secondary-button edit-action"
                    style="border-radius: 5px 0 0 5px"
                    (click)="onCancelEdit(product)"
                  >
                    <i class="icon icon-close"></i>
                  </button>
                </div>
              </div>
              <div class="d-flex mr-3">
                <p class="product-profit-text caption1--bold">الربح:</p>
                <p
                  class="mr-1"
                  [ngClass]="shouldShowVAT ? 'profit-vat-price' : 'product-net-profit'"
                >
                  <span class="body1--bold" *ngIf="!product.sale?.productProfit; else profitOnSale">
                    {{ product.productProfit }}
                  </span>
                  <ng-template #profitOnSale>
                    <div class="mobile-profit-sale">
                      <span
                        ><s class="body1--bold">{{
                          product.productOriginalProfit -
                            product.productSaleOrOrignalPrice +
                            product.customPrice
                        }}</s></span
                      >
                      <p class="body1--bold">
                        {{
                          product.sale?.productProfit -
                            product.productSaleOrOrignalPrice +
                            product.customPrice
                        }}
                      </p>
                    </div>
                  </ng-template>
                </p>
              </div>
            </div>
            <div class="d-flex VAT-container" *ngIf="shouldShowVAT">
              <div class="d-flex">
                <p class="product-profit-text caption1--bold">الضريبة:</p>
                <ng-container *ngIf="product.$vat | async as vat">
                  <p class="text-center profit-vat-price body1--bold">
                    {{ vat }}{{ vat > 0 ? '-' : '' }}
                  </p>
                </ng-container>
              </div>
              <div class="d-flex">
                <p class="product-profit-text caption1--bold">صافي الربح:</p>
                <p class="product-net-profit mr-1 body1--bold">
                  {{ product.$netProfit | async }}
                </p>
              </div>
            </div>
          </div>

          <div *ngIf="product.price !== product.productSaleOrOrignalPrice" class="td__price-change">
            <p class="price-change-text caption1--regular">
              <i class="icon icon-info caption1--regular"></i>
              تم تغيير سعر هذا المنتج من
              <span class="price-text caption1--regular">{{ product.price }}</span> إلى
              <span class="prod-price-text caption1--regular">{{
                product.productSaleOrOrignalPrice
              }}</span>
              {{ currency }}، يرجي تحديث سعر .البيع الجديد
            </p>
          </div>

          <div
            class="mobile-action-buttons d-flex justify-content-around separate-top mt-2"
            [ngClass]="{
              'flex-column align-items-center': isStoresEnabled && userHasStore
            }"
          >
            <button
              appHoverStyle
              [disabled]="
                !product.isAvailableToSeller || product.price !== product.productSaleOrOrignalPrice
              "
              [ngClass]="{
                'disabled-button':
                  !product.isAvailableToSeller ||
                  product.price !== product.productSaleOrOrignalPrice
              }"
              class="action-button add-to-cart-button primary-button"
              (click)="onAddToCart(product)"
            >
              <img loading="lazy" src="../../../assets/img/cart.svg" />
              <span class="body1--bold">اضف الي العربة</span>
            </button>
            <button
              appHoverStyle
              [disabled]="
                !product.isAvailableToSeller ||
                product.price !== product.productSaleOrOrignalPrice ||
                isMarketClosed
              "
              [ngClass]="{
                'disabled-button':
                  !product.isAvailableToSeller ||
                  product.price !== product.productSaleOrOrignalPrice ||
                  isMarketClosed
              }"
              class="action-button order-now-button secondary-button"
              (click)="onOrderNow(product)"
            >
              <span class="body1--bold">أطلب الان</span>
            </button>
            <div
              *ngIf="isStoresEnabled && userHasStore"
              class="add-to-store"
              [ngClass]="product.addedToStore ? 'add-to-store__added' : ''"
            >
              <button
                [disabled]="
                  !product.isAvailableToSeller ||
                  product.price !== product.productSaleOrOrignalPrice
                "
                [ngClass]="{
                  'disabled-button':
                    !product.isAvailableToSeller ||
                    product.price !== product.productSaleOrOrignalPrice
                }"
                class="add-to-store__btn"
                (click)="onAddToStore(product)"
              >
                <img
                  class="add-to-store__btn__icon"
                  src="{{
                    product.addedToStore
                      ? assetsPath + 'added-store.svg '
                      : assetsPath + 'header-icons/shop-basket.svg'
                  }}"
                />
                <p *ngIf="!product.addedToStore" class="body1--bold">
                  {{ trans('STORES.ADD_TO_STORE') }}
                </p>
                <p *ngIf="product.addedToStore" class="body2--bold">
                  {{ trans('STORES.ADDED_TO_STORE') }}
                </p>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <app-order
    *ngIf="isOrderNow"
    [isOrderNow]="true"
    [orderFromProduct]="true"
    [products]="selectedProduct"
    (submitted)="orderSubmitted($event)"
    (back)="reload()"
  ></app-order>
</div>
